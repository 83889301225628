.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.form-container label {
  width: 100%;
  margin-bottom: 10px;
}

.form-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.form-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-container button:hover {
  background-color: #0056b3;
}

.addGroupButton {
  width: 100%;
  text-align: center;
  margin: 12px;
}

/* Responsive styles */
@media (max-width: 600px) {
  .form-container {
    padding: 10px;
  }
}
