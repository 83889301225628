.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #632b30;
  overflow: hidden;
  .name {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.5em;
    min-height: 140px;
    max-height: 140px;
    width: 100%;
    color: white;
    margin: 2px;
    margin-bottom: 6px;
    gap: 8px;
    .restaurant-name {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 4px;
      span {
        margin: 0;
      }
    }
    .logo {
      width: 180px;
    }
  }
  .info {
    text-align: center;
    font-weight: 700;
    font-size: 1.3em;
    margin: 12px 0;
  }
  .name-2 {
    margin: 0;
    font-weight: 900;
    font-size: 1.5em;
    margin-bottom: 12px;
  }
  @media (max-width: 768px) {
    .name,
    .language-picker,
    .info,
    .name-2,
    .menu-picker {
      transition: all 0.7s ease-in-out;
    }
    &.compact {
      .name {
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 100px;
        top: 0;
        left: 0;
        font-size: 1em;
        .logo {
          width: 95px;
        }
      }

      .language-picker {
        position: absolute;
        top: 1em; /* Adjust as needed */
        right: 1em;
      }
      .menu-picker {
        position: absolute;
        top: 4em; /* Adjust as needed */
      }
      .info {
        height: 0;
        opacity: 0;
        margin: 0;
      }
      .name-2 {
        height: 0;
        opacity: 0;
        margin: 0;
      }
    }
  }
}

.number-link {
  text-decoration: none;
  color: #0037ff;
  position: relative;
}
.number-link::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0037ff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.number-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.language-picker,
.menu-picker {
  display: flex;
  gap: 12px;
}

.slide-box {
  margin-top: 6px;
  overflow-y: auto;
  overflow-x: hidden;
}
