.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-y: auto;
  max-width: 1000px;

  .category-card {
    border-radius: 15px;
    min-width: 230px;
    max-width: 230px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    background-color: #632b30;
    color: #ffffff;
    transition: all 0.5s ease-in-out;

    h3 {
      margin: 0 0 4px 0;
    }

    img {
      aspect-ratio: 16/9;
      background-color: #ffebd49a;
      max-width: 100%;
      min-width: 230px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  .category-card:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
}
