.item-card {
  position: relative;
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.23);
  min-width: 230px;
  max-width: 230px;
  border-radius: 15px;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
  background: linear-gradient(340deg, #632b30, #632b30cc);
  box-sizing: border-box;
}

.add-button {
  z-index: 2;
}

.item-card:hover {
  transform: scale(1.01);
  cursor: pointer;
}

.item-img {
  width: 100%;
  border-radius: 15px 15px 0 0;
  aspect-ratio: 16/9;
  background-color: #ffebd49a;
}

.item-text {
  h3 {
    margin: 0 10px;
    width: 100%;
  }
  p {
    margin: 0;
    min-width: 66px;
  }
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4px 0 8px 0;
}

.item-ingredients {
  margin: 0;
  max-height: 0;
  overflow: hidden;
  padding-left: 20px;

  transition: max-height, margin 0.5s ease-in-out;
}

.item-ingredients.show {
  max-height: 200px; // Adjust this value as needed
  text-align: left;
  padding: 0 16px;
  margin: 4px 0 8px 0;
}
