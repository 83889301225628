.my-order {
  width: 100%;
  max-width: 600px;
  transition: height 0.3s ease;
  margin-top: auto;

  &.open {
    max-height: 60vh;
  }

  .my-order-button {
    width: 100%;
    padding: 10px 0;
    background-color: #632b30;
    color: white;
    border: none;
    border-top: 2px white solid;
    border-top-left-radius: 55px;
    border-top-right-radius: 55px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2em;
    line-height: 16px;
    transition-duration: 0.4s;
    font-family: inherit;
    height: 1.7em;
    &:hover {
      background-color: #4caf50; /* Green */
      color: white;
    }
  }

  .order-details {
    color: white;
    background-color: #632b30;
    border: 1px solid #632b30;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;

    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 10px;
      background-color: white;
      color: black;
      border-top: 2px white solid;
      border-top-left-radius: 35px;
      border-top-right-radius: 35px;
    }

    h3 {
      text-align: right;
      margin: 12px;
    }

    .order-list {
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 16px 0;
      .order-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        min-height: 30px;

        span {
          font-size: 1em;
          width: 120px;
          text-align: center;
          padding-left: 4px;
        }
        &:not(:last-child) {
          padding-bottom: 4px;
          border-bottom: 1px solid white;
        }
      }
    }
  }
}
