.categories-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 90%;
  margin: 0 auto;

  .add-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 550px;
    border: 2px solid #632b30;
    padding: 12px;
    border-radius: 24px;
    width: 90%;
    .text-field {
      width: 90%;
    }
  }
  .category-with-items {
    max-width: 100%;
    .the-items {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      margin: 20px 0;
    }
  }
}
.card-content {
  margin: 0 !important;
  padding: 8px !important;
}
.card-text {
  font-size: 1.2em !important;
}

.group-section {
  width: 100%;
  border-radius: 25px;
  border: 2px solid #632b30;

  .groupName {
    width: 100%;
    text-align: center;
    margin: 20px;
    .groupNameButton {
      background-color: #1976d2;
      color: white;
    }
  }
}

.main-group {
  border: 6px solid greenyellow;
}

.cancelGroupDialog {
  background-color: red !important;
  color: white !important;
}
.confirmGroupDialog {
  background-color: green !important;
  color: white !important;
}
