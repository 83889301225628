.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  gap: 16px;
  margin: 4px 0 0 0;
  padding: 20px 0;
}
.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.category-title {
  margin: 0 auto;
}

.items-modal {
  background-image: url("../../../assets/background.png");
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    .category-header .category-title {
      transition: all 0.3s ease-in-out;
    }
    .items {
      transition: all 1s ease-in-out;
    }
    &.compact {
      .category-header .category-title {
        position: absolute;
        top: 0.5em;
      }
      .items {
        margin-top: 0px;
      }
    }
  }
}
